import { Component, JSX, Show } from 'solid-js';
import CloseIcon from '../assets/icons/Close.svg';

export type ModalProps = {
  isOpen: boolean;
  isCloseButton?: boolean;
  onClose?: () => void;
  overlay: boolean;
  children: JSX.Element;
};

const Modal: Component<ModalProps> = (props) => {
  return (
    <Show when={props.isOpen}>
      <div
        id="modal"
        class="animate-fade-in fixed top-0 left-0 z-[1400] h-screen w-screen touch-none
        overflow-hidden
        "
        classList={{
          'bg-modalOverlayBg': props.overlay,
        }}
        onClick={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <div
          id="modal-container"
          class="flex h-full  w-full items-center justify-center"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            id="modal-card"
            class="relative mx-4 flex rounded-3xl bg-white  p-4 sm:mx-0 sm:p-8"
            style={{
              'box-shadow':
                '0px 12px 30px -6px rgba(0, 6, 14, 0.12), 0px 24px 60px -12px rgba(0, 6, 14, 0.2);',
            }}
          >
            <Show when={props.isCloseButton}>
              <div
                class="absolute right-[6px] top-[6px] cursor-pointer"
                onClick={() => {
                  if (props.onClose) {
                    props.onClose();
                  }
                }}
              >
                <CloseIcon />
              </div>
            </Show>
            {props.children}
          </div>
        </div>
      </div>
    </Show>
  );
};

export default Modal;
