import { Component, Show } from 'solid-js';
import VSportLogoSvg from '../assets/icons/vsportlogoby.svg?component-solid';
import Button from './Button';

import IosDownloadBadge from '../assets/images/download-apple.png';
import GooglePlayDownloadBadge from '../assets/images/download-google.png';

const googlePlayStoreLink =
  'https://play.google.com/store/apps/details?id=com.impakt.fitness ';
const appleStoreLink = 'https://ios.impakt.com';

const DownloadOrPlay: Component<{
  platform?: 'android' | 'ios';
  deepLinkToPlay: string;
}> = ({ platform, deepLinkToPlay }) => {
  return (
    <div class="flex h-full  w-full max-w-[360px]">
      <div class="flex flex-col justify-center gap-4">
        <div class="flex justify-center">
          <p class="text-2xl font-medium leading-none tracking-tight text-[#29323B]">
            Open challenge in
          </p>
        </div>
        <div class="mt-2 mb-4 flex flex-col items-center justify-center">
          <VSportLogoSvg />
          <div class="mt-2 ">
            <p class="text-[18px] font-medium leading-none text-[#29323B]">
              for mobile
            </p>
          </div>
        </div>
        <div class="flex min-w-[296px] items-center justify-center ">
          <Button
            href={deepLinkToPlay}
            style="max-h-[48px]"
            text="Open in app"
          />
        </div>
        <div class="flex flex-col items-center justify-center rounded-lg border border-solid border-[#D3E2F0] p-4">
          <div>
            <p class="text-sm font-normal text-[#29323B]">Not installed yet?</p>
          </div>

          <div>
            <Show when={platform === 'ios'}>
              <a
                href={appleStoreLink}
                // eslint-disable-next-line tailwindcss/no-custom-classname
                class="cursor-point mt-2 block h-[52px] w-[180px]"
              >
                <img src={IosDownloadBadge} />
              </a>
            </Show>
            <Show when={platform === 'android'}>
              <a
                href={googlePlayStoreLink}
                class="block h-[52px] w-[179.75px] cursor-pointer"
              >
                <img src={GooglePlayDownloadBadge} />
              </a>
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadOrPlay;
