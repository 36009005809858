import { AnchorProps, Link } from '@solidjs/router';
import { Component, JSX, mergeProps } from 'solid-js';

export type Props = {
  text: string;
  icon?: Component;
  style?: string;
  disabled?: boolean;
  href?: string;
  type?: string;
  onClick?: () => void;
};

const defaultStyle = `flex justify-center items-center
               font-semibold text-white
               text-xl lg:w-48 h-16 rounded-2xl
                w-full
                bg-gradient-to-r from-[#F04153] to-[#F27961] bg-pos-0 hover:from-[#CC4C33] hover:to-[#CC4C33]  transition-all duration-[.2s]`;

const Button: Component<Props> = (props) => {
  const mergedProps = mergeProps({ text: 'Button', disabled: false }, props);

  const ParentElement = (
    props: JSX.HTMLAttributes<HTMLButtonElement> | AnchorProps
  ) =>
    mergedProps.href !== undefined ? (
      <Link {...(props as AnchorProps)} href={mergedProps.href}>
        {props.children}
      </Link>
    ) : (
      <button
        {...(props as JSX.HTMLAttributes<HTMLButtonElement>)}
        onClick={mergedProps.onClick}
        disabled={mergedProps.disabled}
      >
        {props.children}
      </button>
    );

  return (
    <ParentElement class={[defaultStyle, mergedProps.style].join(' ')}>
      <div class="flex items-center gap-6">
        {mergedProps.icon && <mergedProps.icon />}
        <span>{mergedProps.text}</span>
      </div>
    </ParentElement>
  );
};

export default Button;
