import { Component, onMount } from 'solid-js';
import { isIOS } from '../utils';
import Modal from './Modal';
import DownloadOrPlay from './DownloadOrPlay';
import { useLocation, useNavigate } from '@solidjs/router';

interface DownloadOrPlayModalPropsI {
  isOpen: boolean;
  onClose?: () => void;
  deepLinkToPlay: string;
}

const DownloadOrPlayModal: Component<DownloadOrPlayModalPropsI> = ({
  isOpen,
  onClose,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const routineId = location.search.split('&')[0].substring(1);
  const deepLink = `impakt://challenge?${routineId}`;

  onMount(() => {
    if (!routineId) {
      navigate('/');
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overlay
      isCloseButton={onClose !== undefined}
    >
      <DownloadOrPlay
        deepLinkToPlay={deepLink}
        platform={isIOS() ? 'ios' : 'android'}
      />
    </Modal>
  );
};

export default DownloadOrPlayModal;
