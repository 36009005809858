import { Route, Router, Routes } from '@solidjs/router';
// import debug from 'debug';
import { For, lazy, Show } from 'solid-js';
import I18nContextProvider from './context/i18nContext';
import { isIOS, isMobile } from './utils';
import DownloadOrPlayModal from './components/DownloadOrPlayModal';

const Landing = lazy(() => import('./Landing'));
const Kids = lazy(() => import('./pages/kids'));
const Ces = lazy(() => import('./Ces'));
const Game = lazy(() => import('./Game'));
const RepCounterTest = lazy(() => import('./RepCounterTest'));
const KidsPrivacy = lazy(() => import('./pages/kids-privacy'));
const SurveyRedirect = lazy(() => import('./pages/surveys/SurveyRedirect'));

function App() {
  // debug.enable('pose,exercise,unity,fetch');
  const isKids = import.meta.env.VITE_IS_KIDS;
  const landingEnabled = import.meta.env.VITE_ENABLE_LANDING;

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          component={() =>
            !isKids ? (
              <div class="h-screen w-screen">
                <Landing />
                {/* We load the game if device is not mobile, so it's always in a
              ready state */}
                <Show when={!isMobile() && !isIOS()}>
                  <Game />
                </Show>

                <Show when={(isMobile() || isIOS()) && !landingEnabled}>
                  <DownloadOrPlayModal isOpen deepLinkToPlay="" />
                </Show>
              </div>
            ) : (
              <>
                <I18nContextProvider>
                  <Kids />
                </I18nContextProvider>
                <Show when={!isMobile() && !isIOS()}>
                  <Game />
                </Show>

                <Show when={(isMobile() || isIOS()) && !landingEnabled}>
                  <DownloadOrPlayModal isOpen deepLinkToPlay="" />
                </Show>
              </>
            )
          }
        />
        {isKids && (
          <Route
            path="/privacy"
            component={() => (
              <I18nContextProvider>
                <KidsPrivacy />
              </I18nContextProvider>
            )}
          />
        )}
        <Route path="/ces" component={Ces} />
        <Route path="/test" component={RepCounterTest} />
        {isKids && (
          <For each={SurveyLinks}>
            {({ path, link }) => (
              <Route
                path={path}
                component={() => <SurveyRedirect link={link} />}
              />
            )}
          </For>
        )}
      </Routes>
    </Router>
  );
}
const SurveyLinks = [
  {
    path: '/survey/turkish',
    link: 'https://1p3r8oqinug.typeform.com/to/X4SAGluR',
  },
  {
    path: '/survey/french',
    link: 'https://1p3r8oqinug.typeform.com/to/qAVK8fYQ',
  },
  {
    path: '/survey/english',
    link: 'https://1p3r8oqinug.typeform.com/to/nfZtmOAw',
  },
  {
    path: '/survey/german',
    link: 'https://1p3r8oqinug.typeform.com/to/IYuMRSXm',
  },
  {
    path: '/survey/korean',
    link: 'https://1p3r8oqinug.typeform.com/to/JqexN77p',
  },
  {
    path: '/survey/japanese',
    link: 'https://1p3r8oqinug.typeform.com/to/Frrcqa7h',
  },
];

export default App;
